import React from 'react';

import about from './content';
import photos from './photos';

import CemImage from '../../../static/sobre1.png';
import SilvioImage from '../../../static/sobre2.png';
import JorgeImage from '../../../static/sobre3.png';
import ProjetoImage from '../../../static/sobre4.png';
import EquipeImage from '../../../static/sobre5.png';

import * as S from './styles';

const About = () => {
  return (
    <S.Container>
      <S.AboutWrapper>
        <S.TitleTextWrapper>
          <S.Title>{about[0].TitleCem}</S.Title>
          <S.Text className="spotlight">{about[0].TextCem}</S.Text>
        </S.TitleTextWrapper>
        <S.Image src={CemImage} />
      </S.AboutWrapper>
      <S.AboutWrapper className="col-reverse">
        <S.Image src={SilvioImage} />
        <S.TitleTextWrapper>
          <S.Title className="right">{about[0].TitleSilvio}</S.Title>
          <S.Text className="spotlight right">{about[0].TextSilvio}</S.Text>
        </S.TitleTextWrapper>
      </S.AboutWrapper>
      <S.AboutWrapper>
        <S.TitleTextWrapper>
          <S.Title>{about[0].TitleJorge}</S.Title>
          <S.Text>{about[0].TextJorgeFirst}</S.Text>
          <S.TextList>
            <S.TextListItems>
              <S.TextListItem>{about[0].TextJorgeLi1}</S.TextListItem>
            </S.TextListItems>
            <S.TextListItems>
              <S.TextListItem>{about[0].TextJorgeLi2}</S.TextListItem>
            </S.TextListItems>
            <S.TextListItems>
              <S.TextListItem>{about[0].TextJorgeLi3}</S.TextListItem>
            </S.TextListItems>
            <S.TextListItems>
              <S.TextListItem>{about[0].TextJorgeLi4}</S.TextListItem>
            </S.TextListItems>
          </S.TextList>
          <S.Text className="spotlight">{about[0].TextJorgeSecond}</S.Text>
        </S.TitleTextWrapper>
        <S.Image src={JorgeImage} />
      </S.AboutWrapper>
      <S.AboutWrapperOnly>
        <S.TitleTextWrapper>
          <S.Title className="only-text">{about[0].TitleProjeto}</S.Title>
          <S.Text>{about[0].TextProjeto}</S.Text>
        </S.TitleTextWrapper>
        <S.Image className="only-image" src={ProjetoImage} />
        <S.Gallery>
          {photos.map(photo => (
            <S.GalleryImage src={photo.src} alt={photo.alt} />
          ))}
        </S.Gallery>
      </S.AboutWrapperOnly>
      <S.AboutWrapper>
        <S.TitleTextWrapper>
          <S.Title>{about[0].TitleEquipe}</S.Title>
          <S.Text>{about[0].TextEquipe}</S.Text>
        </S.TitleTextWrapper>
        <S.Image src={EquipeImage} />
      </S.AboutWrapper>
    </S.Container>
  );
};

export default About;
