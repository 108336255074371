const photos = [
  {
    src: './gallery1.png',
    alt: 'Antes e depois da reforma',
  },
  {
    src: './gallery2.png',
    alt: 'Antes e depois da reforma',
  },
  {
    src: './gallery3.png',
    alt: 'Antes e depois da reforma',
  },
  {
    src: './gallery4.png',
    alt: 'Antes e depois da reforma',
  },
  {
    src: './gallery5.png',
    alt: 'Antes e depois da reforma',
  },
  {
    src: './gallery6.png',
    alt: 'Antes e depois da reforma',
  },
  {
    src: './gallery7.png',
    alt: 'Antes e depois da reforma',
  },
];

export default photos;
