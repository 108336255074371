import React from 'react';

import SEO from '../components/SEO';
import Layout from '../components/Layout';
import About from '../components/About';

const Sobre = () => {
  return (
    <>
      <SEO title="Sobre" />
      <Layout>
        <About />
      </Layout>
    </>
  );
};

export default Sobre;
