import styled from 'styled-components';
import media from 'styled-media-query';

export const Container = styled.div`
  padding: 0 100px;

  ${media.lessThan('large')`
    padding: 0 70px;
  `};
`;

export const AboutWrapper = styled.div`
  display: flex;
  padding-top: 80px;

  ${media.lessThan('large')`
    flex-direction: column;
    padding-top: 40px;
  `};

  &.col-reverse {
    ${media.lessThan('large')`
      flex-direction: column-reverse;
  `};
  }
`;

export const AboutWrapperOnly = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 140px;

  ${media.lessThan('large')`
    padding: 0;
  `};
`;

export const TitleTextWrapper = styled.div``;

export const Title = styled.h1`
  color: var(--first-color);
  font-size: 22px;
  font-family: var(--first-font);
  font-style: italic;
  padding-bottom: 30px;

  &.right {
    text-align: right;
  }

  &.only-text {
    text-align: center;
    padding-top: 40px;
  }
`;

export const Text = styled.p`
  color: var(--fourth-color);
  font-size: 14px;
  font-family: var(--first-font);
  font-style: italic;
  line-height: 25px;
  position: relative;

  &.right {
    &:before {
      right: 0;
    }
  }

  &.spotlight {
    &:before {
      content: '';
      position: absolute;
      width: 50%;
      height: 2px;
      background-color: #eec854;
      bottom: -20px;

      ${media.lessThan('large')`
        display: none;
      `};
    }
  }
`;

export const TextList = styled.ul`
  margin-left: 18px;
`;

export const TextListItems = styled.li`
  list-style: disc;
  color: var(--fourth-color);
  padding-top: 10px;
`;

export const TextListItem = styled.span`
  color: var(--fourth-color);
  font-size: 14px;
  font-family: var(--first-font);
  font-style: italic;
`;

export const Image = styled.img`
  width: 50%;
  padding: 0 35px;
  position: relative;

  &.only-image {
    width: 100%;
    padding: 30px 0 0;
  }

  ${media.lessThan('large')`
    width: 100%;
    padding: 0;
    margin-top: 20px;
  `};
`;

export const Gallery = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const GalleryImage = styled.img`
  width: 50%;
  padding-top: 20px;

  &:nth-child(odd) {
    padding: 20px 20px 0 0;
  }
`;
