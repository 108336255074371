const about = [
  {
    TitleCem: 'O CEM',
    TextCem:
      'O projeto CEM nasce de uma paixão antiga e eterna entre o empresário Silvio Montechiari e a cidade de Nova Friburgo. Durante toda sua vida, teve como filosofia de trabalho a lei do retorno acreditando que quanto mais fizesse pelo crescimento de sua cidade amada mais a mesma faria pelo seu. A recuperação da antiga fábrica IWEGA e sua transformação no Centro Empresarial Montechiari (CEM) tem como objetivo recuperar para Nova Friburgo o status de uma das cidades de maior potencial econômico do estado do Rio de Janeiro. O desenvolvimento do projeto é um convite feito a toda a população friburguense para uma reflexão da responsabilidade que cada um possui no desenvolvimento do município.',
    TitleSilvio: 'Silvio Montechiari',
    TextSilvio:
      'Nascido em Macuco em 1934 e vindo para Friburgo ainda criança, iniciou sua carreira no ramo de calçados com a fabricação de chinelos. Ainda jovem, virou sua atenção para o ramo têxtil sendo um dos pioneiros no trabalho da transformação de Nova Friburgo em capital brasileira da moda íntima. Além disso, atua nos setores de pecuária e construção civil. Hoje, aos 84 anos, ainda é diretor do Grupo Montechiari e participa de todas as operações das empresas ativamente. Além disso, atua nos setores de pecuária e construção civil. Hoje, aos 84 anos, ainda é diretor do Grupo Montechiari e participa de todas as operações das empresas ativamente.',
    TitleJorge: 'Jorge Montechiari',
    TextJorgeFirst:
      'Bacharel e Mestre em engenharia de produção pela PUC-RJ, atuou como consultor empresarial de 2009 a 2014, onde foi o responsável pelo desenvolvimento de projetos como:',
    TextJorgeLi1: 'Plano Diretor de Logística da Rede Globo;',
    TextJorgeLi2:
      'Simuladores de Avaliação Econômica de Projetos de O&G da Petrobras;',
    TextJorgeLi3:
      'Sistemas de Planejamento de Sondas para Projetos do Pré-Sal;',
    TextJorgeLi4:
      'Sistemas de Apoio a Decisão da Cadeia de Biodiesel da Petrobras Biocombustíveis.',
    TextJorgeSecond:
      'Em 2014, foi chamado pelo seu pai, Silvio Montechiari, para desenvolver os projetos pendentes do grupo familiar, sendo o principal deles, o desenvolvimento e recuperação da antiga fábrica IWEGA. No final de 2016, deu início ao projeto CEM, o qual pretende transformar em um dos mais avançados condomínios industriais da região.',
    TitleProjeto: 'O Projeto',
    TextProjeto:
      'Seguindo os modelos dos condomínios industriais de grandes capitais, o CEM é composto por galpões de pequeno, médio e grande porte. Com aproximadamente 10.000 m² de área recuperada em um período de 10 meses de obras, o Centro Empresarial Montechiari tem localização privilegiada. Localizado a 2km da Praça Getúlio Vargas, a menos de 1km da RJ-130 que liga Nova Friburgo a Teresópolis e da RJ-116 que liga Itaboraí a Itaperuna. O CEM possui uma ampla área de manobra para facilitar a carga e descarga e se preocupa com o controle da superlotação do espaço para o conforto das empresas. Ambiente com banheiros adaptados para pessoas com deficiência, cozinha ampla para uso coletivo do condomínio e individuais em alguns galpões, toda a iluminação é de LED fornecendo energia de modo econômico em todo o espaço. Tudo isso para atender as expectativas de empresas que estão em plena expansão e desejam crescer em um espaço pensado e idealizado para este fim. No final de 2016, deu início ao projeto CEM, o qual pretende transformar em um dos mais avançados condomínios industriais da região.',
    TitleEquipe: 'A Equipe',
    TextEquipe:
      'Seguindo os modelos dos condomínios industriais de grandes capitais, o CEM é composto por galpões de pequeno, médio e grande porte. Com aproximadamente 10.000 m² de área recuperada em um período de 10 meses de obras, o Centro Empresarial Montechiari tem localização privilegiada. Localizado a 2km da Praça Getúlio Vargas, a menos de 1km da RJ-130 que liga Nova Friburgo a Teresópolis e da RJ-116 que liga Itaboraí a Itaperuna. O CEM possui uma ampla área de manobra para facilitar a carga e descarga e se preocupa com o controle da superlotação do espaço para o conforto das empresas. Ambiente com banheiros adaptados para pessoas com deficiência, cozinha ampla para uso coletivo do condomínio e individuais em alguns galpões, toda a iluminação é de LED fornecendo energia de modo econômico em todo o espaço. Tudo isso para atender as expectativas de empresas que estão em plena expansão e desejam crescer em um espaço pensado e idealizado para este fim.',
  },
];

export default about;
